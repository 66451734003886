@import "../../common/common.scss";
.smarthr-sider {
  .ant-menu-submenu-title {
    padding-left: 12px!important;
  }
  .ant-menu-item {
    padding-left: 39px!important;
  }
  .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0!important;
    display: flex;
    justify-content: center;
  }
}
aside {
  > div > div {
    color: #fff;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 23px;
    line-height: 40px;
    + ul {
      height: 100%;
      .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
        font-size: 18px;
        vertical-align: middle;
      }
      .ant-menu-sub li {
        padding-left: 52px !important;
      }
    }
  }
  img {
    max-width: 130px;
    max-height: 70px;
    padding: 5px 0;
    margin: 0 auto;
    display: block;
    cursor: pointer;
  }
  .ant-menu-item {
    text-align: left;
    padding: 0 !important;
    &::after {
      display: none;
    }
  }
  .ant-menu-item-selected {
    font-weight: bold;
  }
  .to-home-viphrm {
    height: 60px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    display: block;
    color: #B5B7BD;
    &:hover {
      color: #373D45;
    }
  }
}

.menuTop {
  display: flex;
  
}