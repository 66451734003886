.grant-manage-card {
  height: 100%;
  .grantItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .num {
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 10px;
    }
    .name {
      text-align: center;
    }
  }
}
