.vh-breadcrumb {
  height: 32px;
  line-height: 32px !important;
  .ant-breadcrumb-separator {
    margin: 0 4px;
    font-size: 14px;
    color: #333;
  }
  & > span {
    font-size: 12px;
    color: #B5B7BD;
    >span.ant-breadcrumb-link {
      font-size: 12px;
      a {
        color: #B5B7BD;
        &:hover {
          color: #4E7BFC;
        }
      }
    }
  }
  & > span:last-child {
    font-size: 12px;
    color: #373D45;
    >span.ant-breadcrumb-link {
      font-size: 12px;
      color: #373D45;
    }
  }
}
