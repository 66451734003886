@import "../../../common/common.scss";
.card-calendar {
  height: 100%;
  .ant-card-body {
    padding: 0;
  }

  .ant-spin {
    max-height: initial !important;
  }
}

.vh-calendar {
  background-color: #fff;
  user-select: none;
  width: 100%;
  border-radius: 6px 6px 0 0;

  .head {
    height: 60px;
    line-height: 60px;
    display: flex;
    padding: 0 24px;
    border-bottom: solid 1px #fff;
    justify-content: space-between;
    border-bottom: 1px solid #F9F9F9;
    strong {
      font-size: 14px;
      color: $font-color;

      + div {
        color: $primary-color;
        span {
          &:nth-child(2) {
            margin-left: 15px;
          }

          &:nth-child(3) {
            margin-right: 15px;
          }
        }
      }
    }

    + div {
      padding: 5px 12px 12px;
      border-top: none;

      thead {
        th {
          height: 35px;
        }
      }

      .ant-fullcalendar-column-header-inner {
        font-weight: bold;
      }

      .ant-fullcalendar-calendar-body {
        padding: 0;
      }

      .ant-fullcalendar-cell {
        text-align: center;
        cursor: pointer;

        span {
          display: inline-block;
          width: 24px;
          height: 24px;
          font-size: 12px;
          color: #999;
          line-height: 21px;
          position: relative;

          &.today:not(.select) {
            &:before {
              content: "";
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: 0;
              width: 17px;
              height: 2px;
              border-radius: 4px;
              background-color: $primary-color;
            }
          }

          &.select {
            border-radius: 50%;
            background-color: $primary-color;
            border: solid 1px $primary-color;
            color: #fff;
          }

          &.done,
          &.undone {
            &:after {
              position: absolute;
              content: "";
              top: -1px;
              right: -1px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
            }
          }

          &.done {
            &:after {
              background-color: $success-color;
            }
          }

          &.undone {
            &:after {
              background-color: $error-color;
            }
          }

          &.select {
            &.done,
            &.undone {
              &:after {
                top: -4px;
                right: -4px;
              }
            }
          }
        }

        &:hover {
          span:not(.today):not(.select) {
            border-radius: 50%;
            background-color: $primary-color;
            border: solid 1px $primary-color;
            line-height: 19px;

            &.done,
            &.undone {
              &:after {
                top: -1.8px;
                right: -2px;
              }
            }
          }
        }

        &.ant-fullcalendar-disabled-cell {
          cursor: initial;
        }
      }
    }
  }

  + div {
    width: 100%;
    // padding: 0 24px 16px 24px;
    padding-bottom: 16px;
    background-color: #fff;
    position: relative;
    border-radius: 0 0 6px 6px;

    .add-todo {
      display: flex;
      justify-content: space-between;
      padding: 5px 24px;
      height: 25px;
      align-items: center;
    }

    .todo-list {
      max-height: 160px;
      overflow-y: auto;
      padding: 0 24px;
    }

    .ant-empty {
      margin-top: 30px;

      .ant-empty-image {
        height: 100%;

        img {
          width: 45px;
          height: 57px;
        }

        + p {
          color: #999;
        }
      }
    }

    form {
      display: flex;
      align-items: center;
      margin: 5px 0;

      .ant-form-item {
        flex: auto;
        height: 40px;
        margin-bottom: 0;

        + .add {
          position: absolute;
          top: 0;
          right: 24px;
        }

        .ant-form-explain {
          display: none;
        }

        + div {
          flex-shrink: 0;
        }
      }
    }

    .todo-item {
      margin: 5px 0;
      display: inline-flex;
      align-items: center;
      position: relative;

      .ant-checkbox-wrapper {
        margin-left: 20px;
        vertical-align: middle;
      }

      > span {
        flex: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        + div {
          flex-shrink: 0;
        }
      }

      &.finish {
        hr {
          background-color: $success-color;
        }

        // &:before {
        //   position: absolute;
        //   top: 20px;
        //   left: 10px;
        //   content: "";
        //   width: calc(100% - 40px);
        //   height: 1px;
        //   background-color: #b7b7b7;
        // }

        span {
          color: $success-color;
        }
      }
    }

    button {
      margin-left: 20px;
      height: 25px;
    }

    hr {
      width: 4px;
      height: 20px;
      background-color: $primary-color;
      border: none;
      border-radius: 2px;
      margin: 0;
      margin-right: 10px;
      flex-shrink: 0;
    }
  }
}

.ant-dropdown.vh-calendar-dd {
  background-color: #fff;

  ul {
    max-height: 350px;
    overflow-y: auto;

    li {
      p {
        margin-bottom: 0;
        text-align: center;

        &.active {
          font-weight: bold;
          color: $primary-color;
        }
      }

      &.ant-dropdown-menu-item-active {
        p {
          color: $primary-color;
        }
      }
    }
  }
}
