.smartht_header {
  border-bottom: 1px solid #F9F9F9;
}

header.ant-layout-header {
  background: #fff;
  // padding: 0 20px;
  padding: 0 20px 0 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.darkTheme {
    background: #333;
  }
  > i.anticon-menu-fold,
  > i.anticon-menu-unfold {
    font-size: 20px;
    color: #999;
  }
  .header-right {
    display: flex;
    align-items: center;
    .ant-badge {
      margin-right: 40px;
      cursor: pointer;
    }
    > span:not(.ant-badge) {
      display: flex;
      justify-content: space-between;
      margin-right: 50px;
      width: 60px;
      align-items: center;
      > img {
        width: 18px;
        height: 18px;
        margin: 0;
        cursor: pointer;
        + i {
          font-size: 22px;
        }
      }
    }
    .ant-dropdown-trigger {
      display: flex;
      p {
        // display: inline-block;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
        font-size: 14px;
        color: #333;
        height: 20px;
        line-height: 20px;
      }
      .ant-avatar {
        margin: 0 3px 0 8px;
      }
      .anticon-down {
        color: #999;
        line-height: 37px;
      }
    }
  }
  .ant-dropdown {
    min-width: initial !important;
    .header-dropdown {
      max-width: 120px;
    }
  }
}
.linkRouter:hover {
  color: #1890ff !important;
}

/** 颜色样式 */
.dropdown-color {
  max-width: 200px;
  li {
    display: inline-block;
    p {
      width: 15px;
      height: 15px;
      margin-bottom: 0;
    }
  }
}
