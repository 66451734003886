$globalBackgroundColor:#F7F8FA;
$moduleBackgroundColor:#fff;
.smartHR-content {
  height: 100%;
  background-color: $globalBackgroundColor!important;
  .ant-layout-content {
    background-color: $globalBackgroundColor!important;
  }
  #childView>div {
    height: 100%!important;
  }
}
.externalAddr {
  height: 100vh;
}

.smartht_bread {
  height: 42px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
  align-items: center;
  border-radius: 6px;
  position: sticky;
  top: 0px;
  z-index: 5;
  button span {
    margin-left: 2px;
  }
}

.external {
  width: 100%;
  height: 100%;
  // background-color: $moduleBackgroundColor;
  border: none;
  display: block;
  min-height: initial;
}