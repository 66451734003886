.service-board-card {
  height: 535px;
  .ant-card-head {
    .title {
      font-weight: bold;
    }
    .subtitle {
      font-size: 12px;
      margin-left: 10px;
      color: #999;
    }
  }
  .tip-remark {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  
  .whiteSpace {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}
