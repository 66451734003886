@import "antd/lib/layout/style";
@import "antd/lib/menu/style";
@import "antd/lib/table/style";
@import "antd/lib/pagination/style";
@import "antd/lib/dropdown/style";
@import "antd/lib/avatar/style";
@import "antd/lib/icon/style";
@import "antd/lib/button/style";
@import "antd/lib/breadcrumb/style";
// @import "antd/lib/row/style";
@import "antd/lib/grid/style";
@import "antd/lib/modal/style";
@import "./common//common.scss";

// $primary-color: #006CB7;
// $success-color: #64AF13;
// $warn-color: #EF8200;
// $error-color: #EA4B6B;

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: $font-color;
  /* scroll bar */
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-track {
    background-color: #fff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 6px;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }

  a:focus {
    text-decoration: none;
  }

  .ant-btn-round {
    font-size: 14px;
  }

  .ant-table td .ant-btn-link {
    padding: 0;
    height: auto;
  }

  .ant-form-item {
    margin-bottom: 12px;
  } 

  .ant-form-item-with-help {
    margin-bottom: 3px;
  }

  .ant-form-explain {
    min-height: 14px;
    line-height: 14px;
  }

  .vh-modal-default {
    .ant-modal-body {
      max-height: 70vh;
      overflow-y: auto;
    }
  }
  form .ant-form-item-label {
    .ant-select, .ant-input {
      width: auto;
    }
  }
  .ant-table-placeholder {
    border: none;
  }
}

// .primary-color {
//   color: $primary-color !important;
// }
// .success-color {
//   color: $success-color !important;
// }
// .warning-color {
//   color: $warning-color !important;
// }
// .error-color {
//   color: $error-color !important;
// }
.normal-color {
  color: #333 !important;
}
.gray-color {
  color: #999 !important;
}

.primary-bg-color {
  background-color: $primary-color;
  color: #fff;
}
.success-bg-color {
  background-color: $success-color;
  color: #fff;
}
.warning-bg-color {
  background-color: $warning-color;
  color: #fff;
}
.error-bg-color {
  background-color: $error-color;
  color: #fff;
}

.primary-color {
  color: $primary-color;
  &:hover {
    color:  $hover-bg-color-primary;
  }
}
.success-color {
  color: $success-color;
}
.warning-color {
  color: $warning-color;
}
.error-color {
  color: $error-color;
}

.ant-btn {
  color: $font-color!important;
}
.ant-btn-ghost {
  border-color: $primary-color!important;
  color: $primary-color!important;
}
.ant-btn-primary {
  color: #fff!important;
  border-color: $primary-color!important;
  background-color: $primary-color!important;
}
.ant-btn-link {
  color: $primary-color!important;
  border: none!important;
}
.ant-btn-link-disabled, .ant-btn-link.disabled, .ant-btn-link[disabled], .ant-btn-link-disabled:hover, .ant-btn-link.disabled:hover, .ant-btn-link[disabled]:hover, .ant-btn-link-disabled:focus, .ant-btn-link.disabled:focus, .ant-btn-link[disabled]:focus, .ant-btn-link-disabled:active, .ant-btn-link.disabled:active, .ant-btn-link[disabled]:active, .ant-btn-link-disabled.active, .ant-btn-link.disabled.active, .ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25)!important;
}
.ant-btn-ghost-disabled, .ant-btn-ghost.disabled, .ant-btn-ghost[disabled], .ant-btn-ghost-disabled:hover, .ant-btn-ghost.disabled:hover, .ant-btn-ghost[disabled]:hover, .ant-btn-ghost-disabled:focus, .ant-btn-ghost.disabled:focus, .ant-btn-ghost[disabled]:focus, .ant-btn-ghost-disabled:active, .ant-btn-ghost.disabled:active, .ant-btn-ghost[disabled]:active, .ant-btn-ghost-disabled.active, .ant-btn-ghost.disabled.active, .ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25)!important;
  border-color: #d9d9d9!important;
}
.ant-btn-primary-disabled, .ant-btn-primary.disabled, .ant-btn-primary[disabled], .ant-btn-primary-disabled:hover, .ant-btn-primary.disabled:hover, .ant-btn-primary[disabled]:hover, .ant-btn-primary-disabled:focus, .ant-btn-primary.disabled:focus, .ant-btn-primary[disabled]:focus, .ant-btn-primary-disabled:active, .ant-btn-primary.disabled:active, .ant-btn-primary[disabled]:active, .ant-btn-primary-disabled.active, .ant-btn-primary.disabled.active, .ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25)!important;
  border-color: #d9d9d9!important;
  background-color: #f5f5f5!important;
}
.ant-btn:hover, .ant-btn:focus {
  color: $primary-color!important;
  border-color: $primary-color!important;
  background-color: #fff!important;
}
.ant-btn-ghost:hover, .ant-btn-ghost:focus {
  color: $primary-color!important;
  border-color: $primary-color!important;
  background-color: $hover-bg-color-ghost!important;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff!important;
  background-color: $hover-bg-color-primary!important;
  border-color: $hover-bg-color-primary!important;
}
.ant-btn-link:hover, .ant-btn-link:focus {
  color:  $hover-bg-color-primary!important;
  background-color: transparent!important;
  border-color:  $hover-bg-color-primary!important;
}
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   color: $primary-color!important;
//   background-color: $hover-bg-color-ghost!important;
// }
.ant-menu.ant-menu-light .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-light .ant-menu-item-selected {
  color: $primary-color!important;
  background-color: $hover-bg-color-ghost!important;
}
.ant-menu.ant-menu-light .ant-menu-item, .ant-menu-submenu-popup.ant-menu-light .ant-menu-item {
  &:hover {
    color: $primary-color!important;
  }
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: $primary-color!important;
}

.ant-menu-item {
  font-weight: normal!important;
  border-radius: 8px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color!important;
  border-color: $primary-color!important;
}
.ant-checkbox-checked::after {
  border-color: $primary-color!important;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5!important;
  border-color: #d9d9d9 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: $primary-color!important;
}
.ant-radio-checked::after {
  border-color: $primary-color!important;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5!important;
  border-color: #d9d9d9 !important;
}
.ant-table {
  a {
    color: $primary-color!important;
  }
  a:hover {
    color:  $hover-bg-color-primary!important;
  }
}
.ant-pagination-item-active {
  border-color: $primary-color !important;
  a {
    color: $primary-color !important;
  }
}
.ant-input, .ant-input-number,
.ant-select, .ant-form-item,
.ant-radio-wrapper {
  color: $font-color!important;
  // font-weight: 500;
}
.ant-input, .ant-select, .ant-input-number,
.ant-calendar-range-picker-input {
  &::placeholder {
    color: #DCE0E5!important;
    // font-weight: 500;
  }
}

.ant-input:focus, .ant-input:hover {
  border-color: $primary-color!important;
}

.ant-select-selection:hover {
  border-color: $primary-color!important;
}

.ant-table {
  color: $font-color!important;
}
.ant-table-placeholder {
  border: none;
}
.ant-table-selection-column {
  // padding-right: 20px!important;
}

.ant-table-thead > tr > th {
  color: $font-color!important;
  font-weight: bold!important;
  border: none!important;
  padding: 16px 12px!important;
  // padding: 14px 10px !important;
  font-size: 13px;
  background-color: #F7F8FA!important;
}

.ant-table-tbody > tr > td {
  border-color: #F9F9F9!important;
  padding: 10px 12px!important;
  // padding: 16px 10px !important;
}

.ant-table-row-selected {
  & > td {
    background: #e6f7ff!important;
  }
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #EFF3FF!important;
}
.ant-form-item-label > label {
  color: $font-color!important;
  font-weight: 500;
}

.vh-calendar .head + div .ant-fullcalendar-cell span.select {
  background-color: $primary-color!important;
  border-color: $primary-color!important;
}

.vh-calendar .head + div .ant-fullcalendar-cell:hover span:not(.today):not(.select) {
  background-color: $hover-bg-color-primary!important;
  border-color: $hover-bg-color-primary!important;
  color: #fff;
}

.vh-calendar .head strong + div {
  color: $primary-color!important;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  &:hover {
    background-color: $hover-bg-color-ghost!important;
    color: $primary-color!important;
    p {
      color: $primary-color!important;
    }
  }
}
.ant-dropdown-menu-item .active {
  color: $primary-color!important;;
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-18 {
  font-size: 18px;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #F8FAFF !important;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #F8FAFF !important;
}
#root {
  .smartHR-content {
    .ant-menu.ant-menu-light {
      .ant-menu-item-selected {
        color: #4E7BFC !important;
      }
    }
    .ant-switch {
      background-color: #D7D7D7;
    }
    .ant-switch-checked {
      background-color: #4378FF;
    }
    .ant-switch-disabled * {
      cursor: default;
    }
    .ant-divider {
      background-color: #f9f9f9;
    }
    .ant-divider-horizontal {
      margin: 20px 0;
    }
    // .ant-btn {
    //   border-color: #4E7BFC !important;
    //   color: #4E7BFC !important;
    // }
    .ant-btn[disabled] {
      cursor: default;
    }
    // .ant-btn-primary {
    //   color: #fff !important;
    //   background-color: #4E7BFC !important;
    // }
    // .ant-btn-primary[disabled] {
    //   color: rgba(0, 0, 0, 0.25)!important;
    //   border-color: #d9d9d9!important;
    //   background-color: #F5F5F5 !important;
    // }
    // .ant-btn-ghost[disabled] {
    //   color: rgba(0, 0, 0, 0.25)!important;
    //   border-color: #d9d9d9!important;
    // }
    .ant-table-header table {
      border-radius: 6px 6px 0 0;
    }
    .ant-table-fixed-right table {
      border-radius: 0 6px 0 0;
    }
    .ant-table-fixed-left table {
      border-radius: 6px 0 0 0;
    }
    .ant-table-thead > tr:first-child > th:first-child {
      border-top-left-radius: 6px;
    }
    .ant-table-thead > tr:first-child > th:last-child {
      border-top-right-radius: 6px
    }
    #salaryManagerRoot {
      >section {
        >section {
          >section {
            // padding: 0;
            .msPayslip {
              padding: 10px 0px;
            }
            .configList {
              padding: 0;
            }
            .salaryList {
              background-color: #F7F8FA;
              .configContent {
                >.title {
                  padding: 23px 17px;
                  font-weight: bold;
                }
              }
              .employeeContent {
                >.top {
                  padding: 13px 17px 10px;
                  border: none;
                }
              }
              .baseConfigContent {
                padding: 0;
                .baseContent {
                  >.top {
                    padding: 15px 20px 15px 17px;
                  }
                  >div:nth-child(2) {
                    padding: 0 17px;
                  }
                  >.ant-row-flex-middle {
                    >div:last-child {
                      padding-top: 10px !important;
                    }
                  }
                }
              }
              .groupItemContent {
                padding: 0;
                >.salaryItemHeader {
                  padding: 15px 20px 15px 17px;
                }
                >.tableSearch {
                  padding: 5px 17px 0;
                }
                >.ant-table-wrapper {
                  padding: 0 17px;
                }
              }
            }
            .payroll-query-list{
              margin: 0 -16px;
              .statistics {
                margin: 0 !important;
                background-color: #F7F8FA;
                padding-bottom: 24px;
                .statistics-item {
                  border-radius: 6px;
                }
              }
              .tableTopArea {
                padding: 16px;
              }
              .ms-table-container-default {
                padding: 0 16px;
              }
            }
            .msPayslipAddEnterprise {
              >div >div >div.ant-steps {
                padding-bottom: 40px;
                width: calc(100% + 64px);
                margin-left: -32px;
                border-bottom: 1px solid #F9F9F9;
              }
              .ant-steps-item-active {
                .ant-steps-item-icon {
                  background-color: #4E7BFC;
                }
              }
            }
          }
        }
      }
    }
  }
}