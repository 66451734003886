@import "../../../common/common.scss";
.quick-action-card {
  height: 100%;
  .box {
    // text-align: center;
    // background-color: #f1f1f1;
    // border-radius: 4px;
    display: flex;
    align-items: center;
    // justify-content: center;
    cursor: pointer;
    margin: 15px 0;
    &:hover {
      color: $primary-color;
    }
  }
}
