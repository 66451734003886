.new-message-card {
  height: 100%;
  .ant-card-body {
    padding: 24px 0;
  }
  .ant-list-item {
    margin: 0 24px;
  }
  .ant-card-head {
    .title {
      font-weight: bold;
    }
    .subtitle {
      font-size: 12px;
      margin-left: 10px;
      color: #999;
    }
  }
  .message-list {
    max-height: 425px;
    overflow-y: auto;
    .title {
      font-weight: bold;
      margin-left: 5px;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .subtitle {
      color: #999;
      margin-top: 5px;
    }
  }
}
