.workbench {
  width: 100%;
  padding: 0 -20px;
  .ant-card {
    height: 100%;
    border-radius: 6px;
  }
  .ant-card.service-board-card {
    overflow-y: auto;
    .ant-card-head {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 5;
    }
  }
  .weight {
    font-weight: 600;
  }
  .subFontColor {
    color: #757b83;
  }
  .personContent {
    background-color: #EBF0FF;
    // height: 220px;
    height: 198px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 60px 0 36px;
    border-radius: 6px;
    .left {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
    .right {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
    }
  }
  .myAgencyContent {
    height: 100%;
    .ant-card-head {
      .title {
        font-weight: bold;
      }
      .subtitle {
        font-size: 12px;
        margin-left: 10px;
        color: #999;
      }
    }
  }
  .ant-card-head {
    border-bottom-color: #F9F9F9;
  }
}
.myAgency {
  height: calc(100% - 62px);
  background-color: #fff;
  padding: 10px 16px;
  border-radius: 6px;
  .agencyForm {
    .ant-form-item {
      width: calc(100% - 16px);
    }
    .ant-form-item-label {
      width: 80px;
    }
    .ant-form-item-control-wrapper {
      width: calc(100% - 80px);
    }
    .statuItem {
      .ant-form-item-label {
        width: 40px;
      }
      .ant-form-item-control-wrapper {
        width: calc(100% - 40px);
      }
    }
  }
}

.serviceProgress {
  .statistics {
    width: 100%;
    margin: 20px 0 !important;

    .ant-col {
      width: calc(20% - 16px);
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.progressForm {
  .ant-form-item {
    width: calc(100% - 16px);
    margin-bottom: 10px;
  }
  .ant-form-item-label {
    width: 80px;
  }
  .ant-form-item-control-wrapper {
    width: calc(100% - 80px);
  }
  .statuItem {
    .ant-form-item-label {
      width: 60px;
    }
    .ant-form-item-control-wrapper {
      width: calc(100% - 60px);
    }
  }
}

.newMessage {
  padding: 10px 16px;
  height: calc(100% - 62px);
  background-color: #fff;
  .messageForm {
    background-color: #fff;
    .ant-form-item {
      width: calc(100% - 16px);
    }
    .ant-form-item-label {
      width: 80px;
    }
    .ant-form-item-control-wrapper {
      width: calc(100% - 80px);
    }
  }
}

.tip-remark {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
